import { AdventCalendar, Sommerles } from "@libry-content/types";
import { isCampaignSeason } from "./isCampaignSeason";

export const isSommerlesSeason = (
  sommerles: Pick<Sommerles, "teaserStartDate" | "startDate" | "endDate">,
  { applyTeaserStartDate } = { applyTeaserStartDate: false }
) => isCampaignSeason({ ...sommerles, applyTeaserStartDate });

export const isAdventCalendarSeason = (adventCalendar: Pick<AdventCalendar, "startDate" | "endDate">) =>
  isCampaignSeason(adventCalendar);

export * from "./types";
